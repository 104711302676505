<template>
  <div class="th-table-responsive shadow-th-light">
    <table :class="['th-table', { 'th-table-alternate': alternateHeading }]">
      <thead>
        <tr>
          <th v-if="showCheckbox">
            <el-checkbox
              :model-value="selectedItems.length === pageData.length"
              @change="handleSelectAll"
            />
          </th>
          <!-- Header columns -->
          <th
            v-for="column in visibleColumns"
            :key="column.key"
            :class="column.class"
          >
            <slot
              :name="`thTableHeader:${column.key}`"
              v-bind="{
                thTableColumn: column,
                thTableCell: column.label
              }"
            >
              {{ column.label }}
            </slot>
          </th>
          <!-- Additonal columns -->
          <th class="text-center" style="width: 1%">
            <el-dropdown :hide-on-click="false" @command="toggleColumn">
              <el-icon class="text-th-secondary text-lg"
                ><CirclePlus
              /></el-icon>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-for="column in columns"
                    :key="column.key"
                    :class="{
                      'text-th-secondary': isVisible(column)
                    }"
                    :command="column"
                  >
                    <el-checkbox
                      :model-value="isVisible(column)"
                      @change="toggleColumn(column)"
                    >
                      {{ column.label }}
                    </el-checkbox>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(item, index) in pageData"
          :key="item[idField] + '' + index"
          class="border-b align-middle hover:bg-th-select-option-hover"
          :class="{
            'cursor-pointer': canClickRow
          }"
          :data-testid="'th-table-row-' + index"
        >
          <td v-if="showCheckbox">
            <el-checkbox
              :model-value="isSelectedItem(item)"
              @change="handleSelectionChange(item)"
            />
          </td>
          <!-- Columns -->
          <td
            v-for="(column, cIndex) in visibleColumns"
            :key="cIndex"
            :class="column.class"
            @click="handleRowClicked(item, index)"
          >
            <slot
              :name="`thTableCell:${column.key}`"
              v-bind="{
                thTableItem: item,
                thTableColumn: column,
                thTableCell: item[column.key]
              }"
            >
              <status-text
                v-if="column.componentType === 'status'"
                :status="item[column.key]"
              />
              <span v-else>{{
                column.formatter ? column.formatter(item) : item[column.key]
              }}</span>
            </slot>
          </td>
          <td
            style="width: 1%"
            :class="{
              'cursor-default': canDeleteRow
            }"
          >
            <!-- Delete -->
            <el-button
              v-if="canDeleteRow"
              plain
              class="el-button--text-icon"
              icon="Delete"
              @click="handleDeleteRow(item, index)"
            />

            <slot name="buttons" :item="item" />
          </td>
        </tr>
        <tr v-if="showTotal" class="border-b">
          <th v-if="showCheckbox" />
          <th
            v-for="column in visibleColumns"
            :key="column.key"
            :class="column.class"
            class="py-3 px-2"
            v-text="column.total ? column.total() : null"
          />
        </tr>
      </tbody>
    </table>

    <!-- No data -->
    <div
      v-if="!data || data.length === 0"
      class="py-8 px-8 text-gray-500 text-sm text-center"
    >
      {{ noDataText || $t('common.data.no_data_yet') }}
    </div>

    <!-- Pagination -->
    <th-pagination
      v-if="data && data.length > 0 && !disablePagination"
      :page-size="pageSizes.inline"
      :total="data.length"
      :current-page="page"
      @current-change="page = $event"
      @size-change="changePageSize($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import safeGet from 'just-safe-get'
import deepClone from 'clone-deep'
import statusText from '@/views/orders/components/status-text.vue'

// Columns example
// [
//   {
//     label: this.$t('pages.products.all.headers.price'), // Header text
//     key: 'price', // Column identification tag
//     visible: true, // Visibility of the column on init (If configuration name is set, it is read from the local configuration)
//     class: 'text-right', // Class for the column element
//     total: () => totalPrice // Value shown at the end row that has the total value of that column
//     formatter: (value) => value.price || '--' // Value to show in the column
//   }
// ]

export default {
  components: {
    statusText
  },
  props: {
    // Local configuration key for saving visible/hidden columns
    configurationName: {
      type: String,
      required: false,
      default: null
    },
    // Data to be displayed in rows
    data: {
      type: Array,
      required: false,
      default: () => []
    },
    // Columns that define row columns
    columns: {
      type: Array,
      required: true
    },
    // Emit row click and style for click
    canClickRow: {
      type: Boolean,
      default: false
    },
    // Show button in row
    canDeleteRow: {
      type: Boolean,
      default: false
    },
    // Replace default 'No data yet' text
    noDataText: {
      type: String,
      default: null
    },
    // Show all data in table
    disablePagination: {
      type: Boolean,
      default: false
    },
    // Show total values that is set in each of the columns
    // When we define the columns to pass, we can add the total value of the summatory of the column
    showTotal: {
      type: Boolean,
      default: false
    },
    // Show checkbox selection of items and select all
    showCheckbox: {
      type: Boolean,
      default: false
    },
    // Fills the value of the cell with the component selected and listed here:
    // components: statusText
    componentType: {
      type: String,
      validator(value) {
        return ['status'].includes(value)
      },
      default: null
    },
    // In case the objects don't have a field called 'id', then we need to specify which field works as the id
    idField: {
      type: String,
      default: 'id'
    },
    // Changes heading styling
    alternateHeading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      page: 1,
      listOfVisibleColumns: [],
      selectedItems: []
    }
  },

  computed: {
    ...mapGetters({
      localConfiguration: 'Config/getLocalConfiguration',
      pageSizes: 'Config/getPageSizes'
    }),

    pageData() {
      if (this.disablePagination) return this.data
      return this.data?.slice(
        (this.page - 1) * this.pageSizes.inline,
        this.page * this.pageSizes.inline
      )
    },

    visibleColumns() {
      return this.columns.filter((c) =>
        this.listOfVisibleColumns.includes(c.key)
      )
    }
  },

  watch: {
    columns() {
      this.init()
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      // Load stored visible columns
      if (!this.configurationName) {
        this.columns.forEach((c) => {
          if (c.visible) this.listOfVisibleColumns.push(c.key)
        })
        return
      }

      const savedColumns = this.loadVisibleColumns()
      if (savedColumns) {
        this.listOfVisibleColumns = deepClone(savedColumns)
      } else {
        this.columns.forEach((c) => {
          if (c.visible) this.listOfVisibleColumns.push(c.key)
        })
        this.saveVisibleColumns()
      }
    },

    isVisible(column) {
      return this.listOfVisibleColumns.includes(column.key)
    },

    loadVisibleColumns() {
      return safeGet(this.localConfiguration, this.configurationName)
    },

    saveVisibleColumns() {
      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: this.configurationName,
        value: deepClone(this.listOfVisibleColumns)
      })
    },

    toggleColumn(column) {
      const isVisible = this.isVisible(column)
      if (isVisible) {
        this.listOfVisibleColumns = this.listOfVisibleColumns.filter(
          (v) => v !== column.key
        )
      } else {
        this.listOfVisibleColumns.push(column.key)
      }

      if (!this.configurationName) return
      this.saveVisibleColumns()
    },

    handleRowClicked(item, index) {
      this.$emit('row-clicked', item, index)
    },

    handleDeleteRow(item, index) {
      this.$emit('row-delete', item, index)
    },

    changePageSize(value) {
      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: 'pageSizes.inline',
        value
      })
    },

    isSelectedItem(item) {
      return this.selectedItems.includes(item[this.idField])
    },

    handleSelectionChange(item) {
      if (this.isSelectedItem(item)) {
        this.selectedItems.splice(
          this.selectedItems.indexOf(item[this.idField]),
          1
        )
      } else {
        this.selectedItems.push(item[this.idField])
      }
      this.$emit('selection-change', this.selectedItems)
    },

    handleSelectAll() {
      const selectAll = this.selectedItems.length !== this.pageData.length
      this.selectedItems = selectAll
        ? this.pageData.map((item) => item[this.idField])
        : []
      this.$emit('selection-change', this.selectedItems)
    }
  }
}
</script>
